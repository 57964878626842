import '@fontsource/inter/latin.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';

// import { datadogRum } from '@datadog/browser-rum';
import { FC, useEffect, useReducer, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme';
import Fonts from 'theme/Fonts';
import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  AuthContextProvider,
  authReducer,
  AuthState
} from 'context/authContext';
import { MemberImpact } from 'models';

//import { DEFAULT_REDIRECT_PAGE } from 'shared/constants';

// datadogRum.init({
//   applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
//   clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
//   site: 'datadoghq.com',
//   service: process.env.NEXT_PUBLIC_DATADOG_APP_NAME || '',
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sampleRate: 100,
//   trackInteractions: true,
//   defaultPrivacyLevel: 'mask-user-input'
// });

// datadogRum.startSessionReplayRecording();

const TITLE = 'Inspired';
const DESCRIPTION =
  'In a world driven by material desires, we are uniting brands, charities and consumers in a harmonious symphony of purpose and impact. ✨';
const IMAGE = `https://${process.env.NEXT_PUBLIC_INSPIRED_MEDIA_HOST}/inspired_meta.png`;
const IS_DEV = process.env.NODE_ENV !== 'production';

const initialAuthState: AuthState = {
  authStatus: 'unauthenticated'
};

interface PageProps {
  member: MemberImpact;
  dehydratedState: DehydratedState;
}

const App: FC<AppProps<PageProps>> = ({ Component, pageProps }) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  //const router = useRouter();
  const [queryClient] = useState(() => new QueryClient());

  useEffect(() => {
    if (IS_DEV) console.log(state);
  }, [state]);

  useEffect(() => {
    dispatch({ type: 'setIsLoading', payload: false });
  }, []);

  return (
    <>
      <Head>
        <title>{TITLE}</title>
        <meta name="robots" content="noindex,nofollow" key="noindex-nofollow" />
        <meta content={DESCRIPTION} name="description" key="description" />
        <meta content={TITLE} property="og:title" key="og:title" />
        <meta
          content={DESCRIPTION}
          property="og:description"
          key="og:description"
        />
        <meta content={IMAGE} property="og:image" key="og:image" />
        <meta content={TITLE} property="twitter:title" key="twitter:title" />
        <meta
          content={DESCRIPTION}
          property="twitter:description"
          key="twitter:description"
        />
        <meta content={IMAGE} property="twitter:image" key="twitter:image" />
        <meta property="og:type" content="website" key="website" />
        <meta
          content="summary_large_image"
          name="twitter:card"
          key="twitter:card"
        />
        <meta
          name="viewport"
          content="maximum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon.png" />
        <link
          rel="shortcut icon"
          type="image/svg+xml"
          sizes="any"
          href="/favicon.svg"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="any"
          href="/favicon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <QueryClientProvider client={queryClient} contextSharing>
        <Hydrate state={pageProps.dehydratedState}>
          <AuthContextProvider initialState={{ state, dispatch }}>
            <ChakraProvider theme={theme}>
              <Fonts />
              <Component {...pageProps} />
              {IS_DEV && <ReactQueryDevtools initialIsOpen={false} />}
            </ChakraProvider>
          </AuthContextProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

export default App;
