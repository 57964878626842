export const breakpoints = {
  sm: 30,
  md: 48,
  lg: 62,
  xl: 80,
  xxl: 96
};

export const mediaQuery = (breakpoint: number) =>
  `@media screen and (min-width: ${breakpoint}em)`;

export const mediaQueryMax = (breakpoint: number) =>
  `@media screen and (max-width: ${breakpoint - 1}em)`;
