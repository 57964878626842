import { createContext, ReactNode, useContext } from 'react';

export type AuthenticationStatus =
  | 'loading'
  | 'unauthenticated'
  | 'authenticated';

export type Action =
  | { type: 'setAuthStatus'; payload: AuthenticationStatus }
  | { type: 'setIsLoading'; payload: boolean }
  | { type: 'setHasAuthError'; payload: boolean }
  | { type: 'setRedirectUrl'; payload: string }
  | { type: 'setIsAuthExpired'; payload: boolean }
  | { type: 'setIsLoggingOut'; payload: boolean }
  | { type: 'setAuthContext'; payload: Partial<AuthState> }
  | { type: 'resetAuth'; payload: null };
export type Dispatch = (action: Action) => void;
export type AuthState = {
  authStatus: AuthenticationStatus;
  isLoading?: boolean;
  hasAuthError?: boolean;
  redirectUrl?: string;
  isAuthExpired?: boolean;
  isLoggingOut?: boolean;
};
type AuthContextProps = {
  state: AuthState;
  dispatch: Dispatch;
};
export type AuthContextProviderProps = {
  children: ReactNode;
  initialState: AuthContextProps;
};

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const authReducer = (state: AuthState, action: Action): AuthState => {
  const { type, payload } = action;
  switch (type) {
    case 'setAuthStatus': {
      return { ...state, authStatus: payload };
    }
    case 'setIsLoading': {
      return { ...state, isLoading: payload };
    }
    case 'setHasAuthError': {
      return { ...state, hasAuthError: payload };
    }
    case 'setRedirectUrl': {
      return { ...state, redirectUrl: payload };
    }
    case 'setIsAuthExpired': {
      return { ...state, isAuthExpired: payload };
    }
    case 'setIsLoggingOut': {
      return { ...state, isLoggingOut: payload };
    }
    case 'setAuthContext': {
      return { ...state, ...payload };
    }
    case 'resetAuth': {
      return {
        ...state,
        authStatus: 'unauthenticated',
        isLoading: false,
        isLoggingOut: true
      };
    }
  }
};

export const AuthContextProvider = ({
  children,
  initialState
}: AuthContextProviderProps) => {
  return (
    <AuthContext.Provider value={initialState}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error(
      'useAuthContext must be used within an AuthContextProvider'
    );
  }
  return authContext;
};
