import { css, Global } from '@emotion/react';

const Fonts = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Obviously';
          src: url('/fonts/Obviously-Regular.woff2');
          src: url('/fonts/Obviously-Regular.woff');
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: 'Obviously';
          src: url('/fonts/Obviously-Semibold.woff2');
          src: url('/fonts/Obviously-Semibold.woff');
          font-weight: 600;
          font-style: normal;
        }
        @font-face {
          font-family: 'Obviously';
          src: url('/fonts/Obviously-Medium.woff2');
          src: url('/fonts/Obviously-Medium.woff');
          font-weight: 500;
          font-style: normal;
        }
        @font-face {
          font-family: 'Obviously Wide';
          src: url('/fonts/Obviously-Wide.woff2');
          src: url('/fonts/Obviously-Wide.woff');
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: 'Obviously Wide';
          src: url('/fonts/Obviously-Wide_Semibold.woff2');
          src: url('/fonts/Obviously-Wide_Semibold.woff');
          font-weight: 600;
          font-style: normal;
        }
        @font-face {
          font-family: 'Obviously Wide';
          src: url('/fonts/Obviously-Wide_Bold.woff2');
          src: url('/fonts/Obviously-Wide_Bold.woff');
          font-weight: bold;
          font-style: normal;
        }
        @font-face {
          font-family: 'Obviously Light';
          src: url('/fonts/Obviously-Light.woff2');
          src: url('/fonts/Obviously-Light.woff');
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: 'Obviously Narrow';
          src: url('/fonts/Obviously-Narrow.woff2');
          src: url('/fonts/Obviously-Narrow.woff');
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: 'Obviously Narrow';
          src: url('/fonts/Obviously-Narrow_Medium.woff2');
          src: url('/fonts/Obviously-Narrow_Medium.woff');
          font-weight: 500;
          font-style: normal;
        }
      `}
    />
  );
};

export default Fonts;
