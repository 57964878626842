import {
  extendTheme,
  ThemeConfig,
  Colors,
  StyleObjectOrFn,
  ButtonProps,
  BadgeProps,
  TooltipProps
} from '@chakra-ui/react';
import { mode, cssVar } from '@chakra-ui/theme-tools';
import { breakpoints, mediaQuery, mediaQueryMax } from 'util/themeUtils';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false
};

const defaultFont =
  '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important';

const screenReaderCSS = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  w: '1px',
  h: '1px',
  m: -'1px',
  overflow: 'hidden',
  p: 0,
  pos: 'absolute'
};

const colors: Colors = {
  brand: {
    red: {
      100: '#fdbbbd',
      200: '#fda2a4',
      300: '#fc898b',
      400: '#fc6f72',
      500: '#fb5659',
      600: '#d6494c',
      700: '#af3c3e',
      800: '#8a2f31',
      900: '#642224'
    },
    green: {
      100: '#afd9c3',
      200: '#92cbad',
      300: '#74bd96',
      400: '#56af7f',
      500: '#38A169',
      600: '#308959',
      700: '#277049',
      800: '#1f583a',
      900: '#16402a'
    },
    border: '#939393',
    text: '#c1c2c5'
  }
};

const styles: StyleObjectOrFn = {
  global: {
    '#__next': {
      minHeight: '100vh',
      '> [data-rk]': {
        minHeight: '100vh'
      }
    },
    body: {
      fontSize: 'xs',
      fontWeight: 300,
      background: '#000',
      minHeight: '100vh',
      color: '#ddd'
    },
    '.chakra-heading': {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#fff',
      fontSize: '2.12rem'
    },
    '.chakra-button': {
      fontFamily: 'Obviously Wide',
      fontWeight: 600,
      lineHeight: 1.1
    },
    '.chakra-text': {
      lineHeight: 'tall'
    },
    '.chakra-alert': {
      fontFamily: defaultFont,
      fontSize: 'md'
    },
    '.default-text': {
      fontFamily: defaultFont,
      fontWeight: 'normal',
      fontSize: '1rem'
    },
    '#chakra-toast-manager-top': {
      pt: ['10px !important', null, null, '58px !important']
    },
    '.chakra-toast__inner': {
      minW: 'none !important'
    },
    '.sr-only': screenReaderCSS,
    '.mobile-sr-only': {
      [mediaQueryMax(breakpoints.md)]: screenReaderCSS
    },
    '.desktop-sr-only': {
      [mediaQuery(breakpoints.md)]: screenReaderCSS
    },
    '.chakra-modal__content': {
      bg: '#fff !important',
      color: '#000 !important',
      textAlign: 'center'
    },
    '.chakra-modal__header': {
      fontFamily: 'Obviously Wide',
      fontWeight: 600,
      display: 'none !important'
    },
    '.chakra-modal__footer': {
      px: '0 !important',
      pb: '0 !important'
    },
    '.image-container': {
      width: '100%',
      '> span': {
        position: 'unset !important'
      },
      img: {
        objectFit: 'contain',
        width: '100% !important',
        position: 'relative !important',
        height: 'unset !important'
      }
    },
    h1: {
      fontSize: '2xl',
      [mediaQuery(breakpoints.md)]: {
        fontSize: '3xl'
      }
    },
    '.ReactQueryDevtools > button > svg': {
      w: '20px',
      h: '20px'
    },
    '.numbers': {
      fontFamily: 'Inter',
      fontWeight: '900'
    }
  }
};

/** Taken from chakra's code to support our custom button */
type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Taken from chakra's code to support our custom button */
/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600'
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600'
  }
};

const components = {
  Button: {
    baseStyle: {
      color: 'white',
      textTransform: 'uppercase'
    },
    sizes: {
      xs: {
        fontSize: '.688rem',
        borderRadius: 'sm'
      },
      sm: {
        fontSize: 'xs'
      },
      md: {
        fontSize: 'sm',
        height: 12
      },
      lg: {
        fontSize: 'md'
      }
    },
    variants: {
      solid: (props: ButtonProps) => {
        const { colorScheme: c } = props;

        if (c === 'gray') {
          const bg = mode(`gray.100`, `whiteAlpha.200`)(props);

          return {
            bg,
            _hover: {
              bg: mode(`gray.200`, `whiteAlpha.300`)(props),
              _disabled: {
                bg
              }
            },
            _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) }
          };
        } else if (c === 'whiteOutline') {
          return {
            bg: 'none',
            border: '1px solid #fff',
            borderRadius: '196.573px',
            fontWeight: '500',
            fontFamily: 'Obviously',
            padding: '8px 32px 10px',
            color: '#fff',
            textTransform: 'none',
            fontSize: '16px',
            lineHeight: '0',
            _hover: {
              bg: 'rgba(255, 255, 255, 0.2)'
            },
            _disabled: {
              bg: 'transparent',
              border: '1px solid',
              borderColor: 'brand.border',
              color: 'brand.border'
            }
          };
        } else if (c === 'blue') {
          return {
            bg: '#0070E0',
            color: '#fff',
            _hover: {
              bg: '#000',
              color: '#fff'
            }
          };
        }
        const {
          bg = `${c}.500`,
          color = 'white',
          hoverBg = `#${c}.600`,
          activeBg = `#${c}.700`
        } = accessibleColorMap[c as string] ?? {};

        const background =
          c === 'brand.red'
            ? mode(bg, `${c}.500`)(props)
            : c === 'brand.hoverRed'
            ? mode(bg, 'black')(props)
            : mode(bg, `${c}.200`)(props);
        const hoverBgProp =
          c === 'brand.red'
            ? mode(hoverBg, `${c}.600`)(props)
            : c === 'brand.hoverRed'
            ? mode(hoverBg, 'brand.red.500')(props)
            : mode(hoverBg, `${c}.300`)(props);
        const colorProp =
          c === 'brand.red'
            ? 'black'
            : c === 'brand.hoverRed'
            ? 'white'
            : mode(color, `gray.800`)(props);
        const hoverColorProp =
          c === 'brand.red' || c === 'brand.hoverRed'
            ? 'black'
            : mode(color, `gray.800`)(props);
        const activeBgProp =
          c === 'brand.red' || c === 'brand.hoverRed'
            ? mode(activeBg, `#d1d1d1`)(props)
            : mode(activeBg, `${c}.400`)(props);

        return {
          bg: background,
          color: colorProp,
          _hover: {
            bg: hoverBgProp,
            color: hoverColorProp,
            _disabled: {
              bg: '#464646'
            }
          },
          _disabled: { bg: '#464646', color: '#7C7575' },
          _active: { bg: activeBgProp },
          _focus: {
            boxShadow:
              c === 'brand.red' || c === 'brand.hoverRed'
                ? '0 0 2px 3px rgba(255, 255, 255, 0.3)'
                : '0 0 0 3px rgba(66, 153, 225, 0.6)',
            bg: hoverBgProp
          }
        };
      }
    }
  },
  FormLabel: {
    baseStyle: {
      fontSize: 'xs',
      fontFamily: 'Obviously Wide',
      fontWeight: '600',
      textTransform: 'uppercase',
      color: 'whiteAlpha.800'
    }
  },
  FormError: {
    baseStyle: {
      text: {
        px: 8,
        fontFamily: defaultFont,
        fontWeight: 500,
        fontSize: 'xs',
        textTransform: 'uppercase',
        color: 'brand.red.500'
      }
    }
  },
  Input: {
    baseStyle: {
      field: {
        fontFamily: 'Obviously',
        backgroundColor: '#212121'
      }
    },
    sizes: {
      md: {
        field: {
          px: 8
        }
      }
    },
    variants: {
      unstyled: {
        field: {
          px: [4, 8],
          py: 3,
          textAlign: ['center', 'left'],
          borderRadius: 0,
          borderColor: 'brand.border',
          borderTopWidth: '2px',
          borderBottomWidth: '2px',
          _focus: {
            boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, 0.48)'
          }
        }
      }
    }
  },
  Checkbox: {
    baseStyle: {
      label: {
        fontFamily: defaultFont,
        fontWeight: 500,
        textTransform: 'uppercase'
      }
    },
    sizes: {
      lg: {
        label: {
          fontSize: 'md'
        }
      },
      md: {
        label: {
          fontSize: 'xs'
        }
      },
      sm: {
        label: {
          fontSize: 'xs'
        }
      }
    },
    variants: {
      'brand.red': () => ({
        label: {
          color: 'brand.red.500'
        }
      })
    }
  },
  Badge: {
    baseStyle: {
      fontFamily: defaultFont,
      fontSize: 'xs',
      p: 0,
      bg: 'none'
    },
    variants: {
      plain: (props: BadgeProps) => {
        const { colorScheme: c } = props;
        return {
          color: `${c}.500`,
          bg: 'none'
        };
      }
    }
  },
  Tooltip: {
    baseStyle: (props: TooltipProps) => {
      const $bg = cssVar('tooltip-bg');
      const $arrowBg = cssVar('popper-arrow-bg');
      const bg = mode('gray.700', 'gray.800')(props);
      return {
        [$bg.variable]: `colors.${bg}`,
        px: '12px',
        py: '4px',
        bg: [$bg.reference],
        [$arrowBg.variable]: [$bg.reference],
        color: mode('whiteAlpha.900', 'whiteAlpha.900')(props),
        borderRadius: 'sm',
        fontWeight: 'medium',
        fontSize: 'sm',
        boxShadow: 'md',
        maxW: '320px',
        zIndex: 'tooltip'
      };
    }
  }
};

const fonts = {
  heading: 'Obviously Wide',
  body: 'Obviously'
};

const fontSizes = {
  xs: '8px',
  sm: '12px',
  md: '16px',
  lg: '18px',
  xl: '20px'
};

const theme = extendTheme({
  colors,
  components,
  config,
  styles,
  fonts,
  fontSizes
});

export default theme;
